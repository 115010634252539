var site = site || {};

(function ($) {
  Drupal.behaviors.basicCarouselFormatter = {
    attached: false,
    isMobile: false,
    attach: function (context) {
      var self = this;
      var bp = Unison.fetch.now();
      var $formatter = $('.js-basic-carousel-formatter-v1', context);

      self.bps = Unison.fetch.all();
      self.isMobile = parseInt(bp.width, 10) < parseInt(self.bps.landscape, 10);

      if (this.attached) {
        return;
      }
      this.attached = true;
      /* Loop through and init the carousels.
         Carousels might contain variations,
         so dynamically change the settings before constructing slick */
      $formatter.each(function () {
        var thisFormatter = $(this);
        var carousel = thisFormatter.find('.js-basic-carousel');
        var $arrowsDiv = thisFormatter.find('.js-carousel-controls-bg');
        var $carouselControls = thisFormatter.find('.js-carousel-controls');
        var basicSlide = '.js-basic-carousel__slide';
        var $slickCounter = thisFormatter.find('.js-basic-carousel-counter');
        var $prevArrow =
          '<div class="slick-prev-button slick--custom"><svg role="img" aria-labelledby="previous" class="icon icon--chevron--left"><use xlink:href="#chevron--left"></use></svg></div>';
        var $nextArrow =
          '<div class="slick-next-button slick--custom"><svg role="img" aria-labelledby="next" class="icon icon--chevron"><use xlink:href="#chevron"></use></svg></div>';
        var useResponsive = false;
        var $hoverPrev = thisFormatter.find(
          '.js-basic-carousel-formatter-hover-arrows-left'
        );
        var $hoverNext = thisFormatter.find(
          '.js-basic-carousel-formatter-hover-arrows-right'
        );
        var $carousel = $(carousel);
        // Autoplay settings
        var autoplay = carousel.attr('data-slides-autoplay');
        // Infinite settings
        var infinite = carousel.attr('dataSlidesInfinite');
        // Slides to show
        var slidesToShow = parseInt(carousel.attr('data-slides-show'), 10);
        // Slides to scroll
        var slidesToScroll = parseInt(carousel.attr('data-slides-scroll'), 10);
        // Responsive dots / arrows
        var pcDotsArrowsArgs = self.checkDotsAndArrows(
          carousel.data('dots-arrows-pc')
        );
        var mobileDotsArrowsArgs = self.checkDotsAndArrows(
          carousel.data('dots-arrows-mobile')
        );
        var settingsPlain;
        var settingsResponsive;
        // Display new arrows
        if ($carouselControls.hasClass('new-arrows')) {
          $prevArrow = $arrowsDiv.find('.slick-prev-button').first();
          $nextArrow = $arrowsDiv.find('.slick-next-button').first();
        }
        // Show Mobile controls
        if ($carousel.data('dotsArrowsMobile') !== 'none' && self.isMobile) {
          thisFormatter.find('.js-carousel-controls').removeClass('hidden');
        }
        // Show Desktop controls
        if ($carousel.data('dotsArrowsPc') !== 'none' && !self.isMobile) {
          thisFormatter.find('.js-carousel-controls').removeClass('hidden');
        }
        // Autoplay settings
        if (typeof autoplay == 'undefined') {
          autoplay = false;
        } else {
          autoplay = true;
        }
        // Infinite settings
        infinite = typeof infinite !== 'undefined';
        if (isNaN(slidesToShow)) {
          slidesToShow = 1;
        } else if (slidesToShow > 3) {
          useResponsive = true;
        }
        if (isNaN(slidesToScroll)) {
          slidesToScroll = 1;
        }
        // Plain with defaults
        settingsPlain = {
          appendArrows: $arrowsDiv,
          slide: basicSlide,
          infinite: infinite,
          slidesToShow: slidesToShow,
          slidesToScroll: slidesToScroll,
          dots: pcDotsArrowsArgs.dots,
          arrows: pcDotsArrowsArgs.arrows
        };
        // Case for custom slides count and dots for mobile
        settingsResponsive = {
          rtl: site.direction.isRTL,
          appendArrows: $arrowsDiv,
          slide: basicSlide,
          infinite: infinite,
          slidesToShow: slidesToShow,
          slidesToScroll: slidesToScroll,
          autoplay: autoplay,
          dots: pcDotsArrowsArgs.dots,
          arrows: pcDotsArrowsArgs.arrows,
          appendDots: $arrowsDiv,
          prevArrow: $prevArrow,
          nextArrow: $nextArrow,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                dots: mobileDotsArrowsArgs.dots,
                arrows: mobileDotsArrowsArgs.arrows,
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        };

        // Init this carousel with our settings
        if (useResponsive === 'true') {
          $carousel.not('.slick-initialized').slick(settingsPlain);
        } else {
          $carousel
            .not('.slick-initialized')
            .on('init', function () {
              self.renderSlickCounter(arguments[1], $slickCounter);
            })
            .on('afterChange', function () {
              self.renderSlickCounter(arguments[1], $slickCounter);
            })
            .slick(settingsResponsive);
        }

        $hoverPrev.once().on('click', function () {
          $carousel.slick('slickPrev');
        });

        $hoverNext.once().on('click', function () {
          $carousel.slick('slickNext');
        });
      });

      $(document).on('basic_modal_formatter:overlay_active', function (e) {
        var $modal = e.payload.$el;
        var $productSelectorContentCard;
        var $slick;

        self.slickSetPosition($modal, true);
        $slick = $modal.find('.js-basic-carousel.slick-initialized');

        // Forces click on the Product Card which is set
        // to be the first product (used for the Vertical Products List)
        $modal.find('.is-first-product').trigger('click');

        // Reset Image Carousel on Modal Open
        if ($slick.length > 0) {
          $slick.slick('slickGoTo', 0);
        }

        if ($modal) {
          $productSelectorContentCard = $modal.find('.js-basic-formatter-item .js-product-content-card');
          // Reset Image Carousel on Product Select
          $productSelectorContentCard.once().on('click', function () {
            $slick = $modal.find('.basic-carousel.slick-initialized');

            if ($slick.length > 0) {
              $slick.slick('slickGoTo', 0);
            }
          });
        }
      });

      $(document).on('basic_modal_formatter:overlay_change', function (e) {
        var $modal = e.payload.$el;

        self.slickSetPosition($modal, false);
      });
    },
    slickSetPosition: function ($modal, setHidden) {
      var $slicks;
      var $slick;
      var $carousels;

      if ($modal.length > 0) {
        $slicks = $modal.find('.slick-initialized');
        $carousels = $modal.find('.js-basic-carousel-formatter-v1');
        $slicks.each(function (index) {
          $slick = $(this);
          if (index > 0 && setHidden) {
            $carousels.eq(index).addClass('hidden');
          }
          $slick.slick('setPosition');
        });
      }
    },
    checkDotsAndArrows: function (dotsAndArrows) {
      var dotsArrowsArgs = {};

      switch (dotsAndArrows) {
        case 'arrows':
          dotsArrowsArgs.dots = false;
          dotsArrowsArgs.arrows = true;
          break;
        case 'dots':
          dotsArrowsArgs.dots = true;
          dotsArrowsArgs.arrows = false;
          break;
        case 'both':
          dotsArrowsArgs.dots = true;
          dotsArrowsArgs.arrows = true;
          break;
        case 'none':
          dotsArrowsArgs.dots = false;
          dotsArrowsArgs.arrows = false;
          break;
        default:
          break;
      }

      return dotsArrowsArgs;
    },
    renderSlickCounter: function (slick, $slickCounter) {
      var currentSlide = slick.currentSlide + 1;

      $slickCounter.html(currentSlide + '/' + slick.slideCount);
    }
  };
})(jQuery);
